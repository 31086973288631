import React, { useState } from 'react';
import { zodResolver } from "@hookform/resolvers/zod";
import { useFirebase } from "../context/SettingAction";
import { Loader2 } from "lucide-react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "./ui/card";
import { Input } from "./ui/input";
import { Button } from "./ui/button";


// Assume these imports are provided by you
// import { updateMinQtyAction } from "@/actions/update-minqty.action";

const schema = z.object({
  minQty: z.coerce.number(),
});

function MOQSettings({ quantity }) {
  const [loading, setLoading] = useState(false);
  const { updateMinQtyAction} = useFirebase();
  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      minQty: quantity || 1,
    },
  });

  const onSubmitAction = async (data) => {
    setLoading(true);
    await updateMinQtyAction(data);
    setLoading(false);
    toast.success(`Updated Order Quantity`);
  };

  return (
    // <div className="max-w-[560px] sm:w-full">
    //   <div className="card-header">
    //     <div className="card-title">Minimum Order Settings</div>
    //     <div className="card-description">
    //       This is the minimum number of sheets a customer can order. Defaults to 1.
    //     </div>
    //   </div>
    //   <div className="card-content">
    //     <form
    //       className="space-y-4"
    //       id="min_qty_form"
    //       onSubmit={form.handleSubmit(onSubmitAction)}
    //     >
    //       <div className="grid gap-1 w-full">
    //         <div className="flex flex-col">
    //           <div className="text-sm mb-2">Minimum Order</div>
    //           <input
    //             min={1}
    //             type="number"
    //             {...form.register("minQty")}
    //           />
    //         </div>
    //       </div>
    //     </form>
    //   </div>
    //   <div className="card-footer">
    //     <button
    //       className="button button-outline w-full"
    //       form="min_qty_form"
    //     >
    //       {loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
    //       Save Min. Order Quantity
    //     </button>
    //   </div>
    // </div>
    <Card className="max-w-[600px] sm:w-full">
      <CardHeader>
        <CardTitle>Minimum Order Settings</CardTitle>
        <CardDescription>
          This is the minimum number of sheets a customer can order. Defaults to
          1.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <form
          className="space-y-4"
          id="min_qty_form"
          onSubmit={form.handleSubmit(onSubmitAction)}
        >
          <div className="grid gap-1  w-full">
            <div className="flex flex-col">
              <div className="text-sm mb-2">Minimum Order</div>
              <Input min={1} type="number" {...form.register("minQty")} />
            </div>
          </div>
        </form>
      </CardContent>
      <CardFooter>
        <Button variant="outline" className="w-full" form="min_qty_form">
          {loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
          Save Min. Order Quantity
        </Button>
      </CardFooter>
    </Card>
  );
}

export default MOQSettings;
