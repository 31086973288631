import React, { useState, useEffect, useCallback } from "react";
import { useFirebase } from "../context/SettingAction";
import { zodResolver } from "@hookform/resolvers/zod";
import { Loader2 } from "lucide-react";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { toast } from "react-hot-toast";
import { FaTrashAlt } from "react-icons/fa";
import { z } from "zod";
import { nanoid } from "nanoid";
import CreatableSelect from 'react-select/creatable';
import { default as CustomSelect } from 'react-select';


import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
// import {
//   Select,
//   SelectContent,
//   SelectItem,
//   SelectTrigger,
//   SelectValue,
// } from "../components/ui/select";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import { Separator } from "../components/ui/separator";
import { cmToIn, inTo300DpiPx } from "../lib/utils";

export const schema = z.object({
  width: z.coerce.number(),
  height: z.coerce.number(),
  price: z.coerce.number(),
  unit: z.union([z.literal("cm"), z.literal("in")]),
});

const SizeSettings = (/*{ sheetSizes } */) => {
  // const [loading, setLoading] = useState(false);
  // const [sizes, setSizes] = useState([]);
  // const { updateSheetSize, removeOrder } = useFirebase();
  // const {
  //   handleSubmit,
  //   formState: { errors },
  //   register,
  //   control,
  //   reset,
  //   watch,
  // } = useForm({
  //   resolver: zodResolver(schema),
  //   defaultValues: {
  //     width: 1,
  //     price: 1,
  //     height: 1,
  //     unit: "in",
  //   },
  // });

  // const unit = watch("unit");

  // const onSubmit = async (data) => {
  //   setLoading(true);
  //   await updateSheetSize(data);
  //   setSizes([...sizes, data]);
  //   reset(); // Reset form after submission
  //   toast.success(`Updated sizes`, {});
  //   setLoading(false);
  // };

  // useEffect(() => {
  //   setSizes(sheetSizes);
  // }, [sheetSizes]);

  const { configs } = useFirebase();

  const [loading, setLoading] = useState(false);
  const { updateSheetSize, updateCategory, removeOrder } = useFirebase();
  const [category, setCategory] = useState(configs?.category || []); 
  const schema2 = z.object({
    sizes: z.array(
      z.object({
        width: z.coerce.number(),
        height: z.coerce.number(),
        price: z.coerce.number(),
        unit: z.union([z.literal("cm"), z.literal("in")]),
        // category: z.string().optional(),
      })
    ),
  });

  const form = useForm({
    resolver: zodResolver(schema2),
    defaultValues: {
      sizes: configs?.sizes || [],
    },
  });

  const sizesFieldArray = useFieldArray({
    control: form.control,
    name: "sizes",
    rules: {},
  });
  const watchedSheetSize = form.watch("sizes", sizesFieldArray.fields);
  
  const onEditSheetSubmit = async (data) => {
    try {
      setLoading(true);
      const normalised = watchedSheetSize.map((size) => ({
        ...size,
        width:
          size.unit === "cm"
            ? Math.min(size.width, 109 * 2.54)
            : Math.min(size.width, 109),
        height:
          size.unit === "cm"
            ? Math.min(size.height, 109 * 2.54)
            : Math.min(size.height, 109),
        computedWidth:
          size.unit === "in"
            ? inTo300DpiPx(size.width)
            : inTo300DpiPx(cmToIn(size.width)),
        computedHeight:
          size.unit === "in"
            ? inTo300DpiPx(size.height)
            : inTo300DpiPx(cmToIn(size.height)),
        category:  size.category ?  {value:size.category.value, label: size.category.label }  : null
  
      }));

      await updateSheetSize(normalised);
      // await updateCategory(category);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleCreateOption = (inputValue, id) => {
  
    if (inputValue && inputValue.trim() !== '') {
      const lowerCaseInput = inputValue.toLowerCase();
      // Avoid adding duplicates (case-insensitive)
      if (!category.some((val) => val.toLowerCase() === lowerCaseInput)) {
        setCategory((prevCategory) => [...prevCategory, lowerCaseInput.trim()]);
      }
    }
  };
  
  return (
    <Card className="max-w-[600px] sm:w-full">
      <CardHeader>
        <CardTitle>Sheet Size & Pricing</CardTitle>
        <CardDescription>
          The gang sheet sizes available to your customers and their prices. The
          current max size is 108x108 inches (274 x 274 cm).
        </CardDescription>
      </CardHeader>

      {!configs?.sizes ? (
        <CardContent>
          <Loader2 className="mr-2 h-8 w-8 animate-spin" />
        </CardContent>
      ) : (
        <CardContent>
          <div className="space-y-5">
            <form onSubmit={form.handleSubmit(onEditSheetSubmit)}>
              <div className="grid gap-3 w-full">
                {sizesFieldArray?.fields?.map((size, idx) => (
                  <div
                    key={size.id}
                    className="flex items-center justify-between space-x-4 flexDiv-sizesheet"
                  >
                    <div className="flex flex-col flex-1">
                      <div className="text-sm">Width</div>
                      <Input
                        min={1}
                        max={size.unit === "in" ? 109 : 276.86}
                        step={0.01}
                        type="number"
                        defaultValue={size.width}
                        {...form.register(`sizes.${idx}.width`)}
                      />
                    </div>
                    <div className="flex flex-col flex-1">
                      <div className="text-sm">Height</div>
                      <Input
                        min={1}
                        max={size.unit === "in" ? 108 : 274}
                        type="number"
                        defaultValue={size.height}
                        {...form.register(`sizes.${idx}.height`)}
                      />
                    </div>
                    <div className="flex flex-col flex-1">
                      <div className="text-sm">Unit</div>
                      <Controller
                        control={form.control}
                        name={`sizes.${idx}.unit`}
                        rules={{ required: true }}
                        defaultValue={size.unit} // Ensure defaultValue is set correctly
                        render={({ field }) => (
                          <select
                            value={field.value}
                            onChange={(value) => field.onChange(value)}
                            className="flex h-10 w-full items-center justify-between rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1"
                          >
                            <option value="">Select Unit</option>
                            <option value="in">in</option>
                            <option value="cm">cm</option>
                          </select>
                          // <Select
                          //   value={field.value}
                          //   onValueChange={(value) => field.onChange(value)}
                          // >
                          //   <SelectTrigger className="ml-auto">
                          //     <SelectValue placeholder="Select Unit" />
                          //   </SelectTrigger>
                          //   <SelectContent>
                          //     <SelectItem value="in">in</SelectItem>
                          //     <SelectItem value="cm">cm</SelectItem>
                          //   </SelectContent>
                          // </Select>
                        )}
                      />
                    
                    </div>

                    <div className="flex flex-col flex-1" style={{width: "100px"}}>
                      <div className="text-sm">Category</div>
                      <div className="create-suggestion-none">
                      <Controller
                        control={form.control}
                        name={`sizes.${idx}.category`} // Use Controller to register the category field
                        render={({ field }) => (
                          <CustomSelect
                            {...field} // Spread the field props to connect it with React Hook Form
                            onCreateOption={(inputValue) => handleCreateOption(inputValue, idx)}
                            options={category.map((cat) => ({ value: cat, label: cat }))}
                          />
                        )}
                      />
                      </div>
                      
                  
                    </div>

                    <div className="flex flex-col flex-1 flex-Div-margin">
                      <div className="text-sm">Price</div>
                      <Input
                        min={1}
                        step={0.01}
                        type="number"
                        defaultValue={size.price}
                        {...form.register(`sizes.${idx}.price`)}
                        required
                      />
                    </div>
                    <div className="font-medium border-r-0 self-end trash-button rounded">
                      <Button
                        type="button"
                        onClick={() => sizesFieldArray.remove(idx)}
                        size="sm"
                        className="trash-button"
                      >
                        <FaTrashAlt />
                      </Button>
                    </div>
                  </div>
                ))}

                <div className="flex flex-col items-end">
                  <Button
                    className="font-medium border-r-0 add-item-order-btn"
                    size="sm"
                    variant="default"
                    onClick={() => {
                      sizesFieldArray.append({
                        width: 1,
                        height: 1,
                        unit: "in",
                        key: nanoid(),
                      });
                    }}
                  >
                    Add Size
                  </Button>
                </div>
                <div>
                  <Button
                    type="submit"
                    className="w-full save-currency-btn"
                    size="sm"
                    variant="default"
                  >
                    {loading && (
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    )}
                    Save Sheet Size Settings
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </CardContent>
      )}
    </Card>
  );
};


export default SizeSettings;
