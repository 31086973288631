import React, { useState } from 'react';
import { zodResolver } from "@hookform/resolvers/zod";
import { Loader2 } from "lucide-react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";
import { useFirebase } from "../context/SettingAction";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "./ui/card";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select";

// Assume these imports are provided by you
// import { updateBleedAction } from "@/actions/update-bleed.action";
// import { Icons } from "./icons";

const schema = z.object({
  bleed: z.coerce.number(),
  bleed_unit: z.union([z.literal("cm"), z.literal("in")]),
});

function BleedSettings() {
  const [loading, setLoading] = useState(false);
  const {updateBleedAction} = useFirebase();
  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      bleed: 1,
      bleed_unit: "in",
    },
  });

  const onSubmitAction = async (data) => {
    setLoading(true);
    await updateBleedAction(data);
    form.reset();
    setLoading(false);
    toast.success(`Updated Bleed Preferences`);
  };

  return (
    // <div className="max-w-[560px] sm:w-full">
    //   <div className="card-header">
    //     <div className="card-title">Bleed Settings</div>
    //   </div>
    //   <div className="card-content">
    //     <div className="space-y-4">
    //       <form
    //         id="bleed_settings_form"
    //         className="grid gap-1 w-full"
    //         onSubmit={form.handleSubmit(onSubmitAction)}
    //       >
    //         <div className="flex items-center justify-between space-x-4">
    //           <input
    //             min={0}
    //             step={0.01}
    //             type="number"
    //             {...form.register("bleed")}
    //           />
    //           <select
    //             {...form.register("bleed_unit", { required: true })}
    //           >
    //             <option value="in">in</option>
    //             <option value="cm">cm</option>
    //           </select>
    //         </div>
    //       </form>
    //     </div>
    //   </div>
    //   <div className="card-footer">
    //     <button
    //       className="button button-outline w-full"
    //       type="submit"
    //       form="bleed_settings_form"
    //     >
    //       {loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
    //       Save Bleed Settings
    //     </button>
    //   </div>
    // </div>
    <Card className="max-w-[600px] sm:w-full">
      <CardHeader>
        <CardTitle>Bleed Settings</CardTitle>
      </CardHeader>
      <CardContent>
        {/* <div className="flex space-x-2">
          <Input value="http://example.com/link/to/document" readOnly />
          <Button variant="secondary" className="shrink-0">
            Copy Link
          </Button>
        </div> */}
        {/* <Separator className="my-4" /> */}
        <div className="space-y-4">
          <form
            id="bleed_settings_form"
            className="grid gap-1  w-full"
            onSubmit={form.handleSubmit(onSubmitAction)}
          >
            <div className="flex items-center justify-between space-x-4">
              <Input
                min={0}
                step={0.01}
                type="number"
                {...form.register("bleed")}
              />
              <Controller
                control={form.control}
                name="bleed_unit"
                rules={{ required: true }}
                render={({ field }) => (
                  <Select onValueChange={field.onChange} {...field}>
                    <SelectTrigger className="ml-auto">
                      <SelectValue placeholder="Select Unit" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="in">in</SelectItem>
                      <SelectItem value="cm">cm</SelectItem>
                    </SelectContent>
                  </Select>
                )}
              />
            </div>
          </form>
        </div>
      </CardContent>
      <CardFooter>
        <Button
          variant="outline"
          type="submit"
          className="w-full save-currency-btn"
          form="bleed_settings_form"
        >
          {loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
          Save Bleed Settings
        </Button>
      </CardFooter>
    </Card>
  );
}

export default BleedSettings;
