import React, { useState, useEffect } from "react";
import { useFirebase } from "../context/SettingAction";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";

import { Button } from "./ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "./ui/card";
import { RadioGroup, RadioGroupItem } from "./ui/radio-group";
import { Form, FormField, FormItem, FormLabel, FormMessage } from "./ui/form";
import { getSymbolForCurrency } from "../lib/utils";

const schema = z.object({
  currency: z.enum(["USD", "EUR", "CAD", "AUD", "GBP"]),
});

function CurrencySettings(/*{ currency }*/) {
  const { configs } = useFirebase();
  const { currency } = {...configs};

  const [loading, setLoading] = useState(false);
  const { updateCurrencyAction } = useFirebase();
  const [symbol, setSymbol] = useState(() => {
    const storedSymbol = localStorage.getItem("currencySymbol");
    return storedSymbol || "$";
  });
  const [selectedCurrency, setSelectedCurrency] = useState(currency || "USD"); // State to track selected currency

  const { handleSubmit, control, reset } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      currency: currency || "USD",
    },
  });

  useEffect(() => {
    localStorage.setItem("currencySymbol", symbol);
  }, [symbol]);

  const onSubmitAction = async (data) => {
    setLoading(true);
    try {
      await updateCurrencyAction(data);
      reset();
      toast.success(`Updated Currency Settings`);
      const currencySymbol = getSymbolForCurrency(data.currency);
      setSymbol(currencySymbol);
      localStorage.setItem("currencySymbol", currencySymbol);
      setSelectedCurrency(data.currency); // Update selected currency state
    } catch (error) {
      toast.error(`Error updating currency settings: ${error.message}`);
    }
    setLoading(false);
  };

  return (
    <Card className="max-w-[600px] sm:w-full">
      <CardHeader>
        <CardTitle>Currency Settings</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <Form>
            <form
              id="currency_settings_form"
              className="grid gap-1 w-full"
              onSubmit={handleSubmit(onSubmitAction)}
            >
              <FormField
                control={control}
                name="currency"
                render={({ field }) => (
                  <FormItem className="space-y-3">
                    <FormLabel>Default currency.</FormLabel>
                    <RadioGroup
                      onValueChange={(value) => {
                        field.onChange(value);
                        setSelectedCurrency(value);
                      }}
                      defaultValue={selectedCurrency}
                      className="flex flex-col space-y-1"
                    >
                      {["USD", "CAD", "EUR", "AUD", "GBP"].map((currency) => (
                        <FormItem
                          key={currency}
                          className="flex items-center space-x-3 space-y-0"
                        >
                          <RadioGroupItem
                            value={currency}
                            checked={selectedCurrency === currency}
                          />
                          <FormLabel className="font-normal">
                            {currency}
                          </FormLabel>
                        </FormItem>
                      ))}
                    </RadioGroup>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </form>
          </Form>
        </div>
      </CardContent>
      <CardFooter>
        <Button
          variant="outline"
          type="submit"
          className="w-full save-currency-btn"
          form="currency_settings_form"
        >
          {loading && <div className="mr-2 h-4 w-4 animate-spin"></div>}
          Save Currency Settings
        </Button>
      </CardFooter>
    </Card>
  );
}

export default CurrencySettings;
