import React, { useState } from 'react';
import { zodResolver } from "@hookform/resolvers/zod";
import { Loader2, Trash2 } from "lucide-react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useFirebase } from "../context/SettingAction";
import { z } from "zod";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "./ui/card";
import { Input } from "./ui/input";
import { Button } from "./ui/button";



const schema = z.object({
  shipping: z.array(
    z.object({
      min: z.number(),
      max: z.number(),
      cost: z.number(),
    })
  ),
});

function ShippingSettings({ shipping }) {
  const [loading, setLoading] = useState(false);
  const { updateShippingAction} = useFirebase();

  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      shipping,
    },
  });

  const shippingFieldArray = useFieldArray({
    control: form.control,
    name: "shipping",
  });

  const onSubmit = async (data) => {
    setLoading(true);
    await updateShippingAction(data);
    setLoading(false);
    toast.success(`Updated Shipping`);
  };

  const watchedShipping = form.watch("shipping", shippingFieldArray.fields);

  return (
    // <div className="max-w-[560px] sm:w-full">
    //   <div className="card-header">
    //     <div className="card-title">Shipping</div>
    //   </div>
    //   <div className="card-content">
    //     <div className="space-y-4">
    //       <form onSubmit={form.handleSubmit(onSubmit)} id="shipping_config_form">
    //         <div className="grid gap-1 w-full">
    //           {shippingFieldArray.fields.map((field, idx) => {
    //             return (
    //               <div key={field.id} className="flex items-center justify-between space-x-4">
    //                 <div className="flex flex-col flex-1 space-y-2">
    //                   <div className="text-sm">Min Order($)</div>
    //                   <Controller
    //                     name={`shipping.${idx}.min`}
    //                     control={form.control}
    //                     render={({ field }) => (
    //                       <input
    //                         disabled={field.disabled}
    //                         min={watchedShipping[idx - 1]?.max || 0}
    //                         max={watchedShipping[idx].max}
    //                         step={0.01}
    //                         type="number"
    //                         {...field}
    //                         onChange={(e) => field.onChange(+e.target.value)}
    //                       />
    //                     )}
    //                   />
    //                 </div>
    //                 <div className="flex flex-col flex-1 space-y-2">
    //                   <div className="text-sm">Max Order($)</div>
    //                   <Controller
    //                     name={`shipping.${idx}.max`}
    //                     control={form.control}
    //                     render={({ field }) => (
    //                       <input
    //                         type="number"
    //                         max={
    //                           idx === shippingFieldArray.fields.length - 1
    //                             ? Number.MAX_SAFE_INTEGER
    //                             : watchedShipping[idx + 1]?.min
    //                         }
    //                         min={(watchedShipping[idx]?.min || 0) + 0.01}
    //                         step={0.01}
    //                         {...field}
    //                         onChange={(e) => field.onChange(+e.target.value)}
    //                       />
    //                     )}
    //                   />
    //                 </div>
    //                 <div className="flex flex-col flex-1 space-y-2">
    //                   <div className="text-sm">Cost ($)</div>
    //                   <Controller
    //                     name={`shipping.${idx}.cost`}
    //                     control={form.control}
    //                     render={({ field }) => (
    //                       <input
    //                         type="number"
    //                         step={0.01}
    //                         {...field}
    //                         onChange={(e) => field.onChange(+e.target.value)}
    //                         min={0}
    //                       />
    //                     )}
    //                   />
    //                 </div>
    //                 <div className="flex flex-col flex-1 space-y-2">
    //                   <div className="text-sm opacity-0">Cost ($)</div>
    //                   {idx === watchedShipping.length - 1 && (
    //                     <Button
    //                       type="button"
    //                       className="font-medium border-r-0 self-end"
    //                       size="sm"
    //                       variant="default"
    //                       onClick={() => shippingFieldArray.remove(idx)}
    //                     >
    //                       <Trash2 />
    //                     </Button>
    //                   )}
    //                 </div>
    //               </div>
    //             )
    //           })}
    //           <div className="flex flex-col items-end">
    //             <Button
    //               type="button"
    //               className="font-medium border-r-0"
    //               size="sm"
    //               variant="default"
    //               onClick={() => {
    //                 const lastItem =
    //                   form.getValues("shipping")[watchedShipping.length - 1];

    //                 shippingFieldArray.append({
    //                   min: Number(lastItem.max.toFixed(2)),
    //                   max: Number(lastItem.max.toFixed(2)) + 1,
    //                   cost: 0,
    //                 });
    //               }}
    //             >
    //               Add Rule
    //             </Button>
    //           </div>
    //         </div>
    //       </form>
    //     </div>
    //   </div>
    //   <div className="card-footer">
    //     <Button
    //       type="submit"
    //       form="shipping_config_form"
    //       variant="outline"
    //       className="w-full"
    //     >
    //       {loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
    //       Save Shipping Settings
    //     </Button>
    //   </div>
    // </div>
    <Card className="max-w-[600px] sm:w-full">
    <CardHeader>
      <CardTitle>Shipping</CardTitle>
    </CardHeader>
    <CardContent>
      <div className="space-y-4">
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          id="shipping_config_form"
        >
          <div className="grid gap-1  w-full">
            {shippingFieldArray.fields.map((field, idx) => {
              return (
                <div
                  key={field.id}
                  className="flex items-center justify-between space-x-4"
                >
                  <div className="flex flex-col flex-1 space-y-2">
                    <div className="text-sm form-label-title">Min Order($)</div>

                    <Controller
                      name={`shipping.${idx}.min`}
                      control={form.control}
                      render={({ field }) => {
                        return (
                          <Input
                            disabled={field.disabled}
                            min={watchedShipping[idx - 1]?.max || 0}
                            max={watchedShipping[idx].max}
                            step={0.01}
                            // readOnly
                            type="number"
                            {...field}
                            onChange={(e) => field.onChange(+e.target.value)}
                          />
                        )
                      }}
                    />
                  </div>
                  <div className="flex flex-col flex-1 space-y-2">
                    <div className="text-sm form-label-title">Max Order($)</div>

                    <Controller
                      name={`shipping.${idx}.max`}
                      control={form.control}
                      render={({ field }) => {
                        return (
                          <Input
                            type="number"
                            max={
                              idx === shippingFieldArray.fields.length - 1
                                ? Number.MAX_SAFE_INTEGER
                                : watchedShipping[idx + 1]?.min
                            }
                            min={(watchedShipping[idx]?.min || 0) + 0.01}
                            step={0.01}
                            {...field}
                            onChange={(e) => field.onChange(+e.target.value)}
                          />
                        )
                      }}
                    />
                  </div>
                  <div className="flex flex-col flex-1 space-y-2">
                    <div className="text-sm form-label-title">Cost ($)</div>

                    <Controller
                      name={`shipping.${idx}.cost`}
                      control={form.control}
                      render={({ field }) => {
                        return (
                          <Input
                            type="number"
                            step={0.01}
                            {...field}
                            onChange={(e) => field.onChange(+e.target.value)}
                            min={0}
                          />
                        )
                      }}
                    />
                  </div>

                  <div className="flex flex-col flex-1 space-y-2">
                    <div className="text-sm opacity-0 form-label-title small-label-pricetrash">Cost ($)</div>

                    {idx === watchedShipping.length - 1 && (
                      <Button
                        type="button"
                        className="font-medium border-r-0 self-end trash-button"
                        size="sm"
                        variant="default"
                        onClick={() => shippingFieldArray.remove(idx)}
                      >
                        <Trash2 />
                      </Button>
                    )}
                  </div>
                </div>
              )
            })}

            <div className="flex flex-col items-end">
              <div className="text-sm opacity-0">Unit</div>
              <Button
                type="button"
                className="font-medium border-r-0 add-item-order-btn"
                size="sm"
                variant="default"
                onClick={() => {
                  const lastItem =
                    form.getValues("shipping")[watchedShipping.length - 1]

                  shippingFieldArray.append({
                    min: Number(lastItem.max.toFixed(2)),
                    max: Number(lastItem.max.toFixed(2)) + 1,
                    cost: 0,
                  })
                }}
              >
                Add Rule
              </Button>
            </div>
          </div>
        </form>
      </div>
    </CardContent>
    <CardFooter>
      <Button
        type="submit"
        form="shipping_config_form"
        variant="outline"
        className="w-full save-currency-btn"
      >
        {loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
        Save Shipping Settings
      </Button>
    </CardFooter>
  </Card>
  );
}

export default ShippingSettings;
