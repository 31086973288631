import React, { useState, useEffect } from "react";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "./ui/card";
import { useFirebase } from "../context/SettingAction";
import { Loader2, Trash2 } from "lucide-react";
import { useForm, useFieldArray } from "react-hook-form";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { toast, ToastContainer } from "react-toastify";

const CategorySetting = () => {
    const { configs, updateCategory } = useFirebase();
    const [loading, setLoading] = useState(false);
    const [categoryArr, updateCategoryArr] = useState(configs?.category || []) 

    const form = useForm({
        defaultValues: {
            category: configs?.category?.length 
                ? configs.category.map(cat => ({ name: cat })) 
                : [{ name: "" }], // Ensure at least one empty category field
        },
    });

    useEffect(() => {
        // You can add any specific logic here if needed
    }, [categoryArr])

    const categoryFieldArray = useFieldArray({
        control: form.control,
        name: "category",
    });

    const onCategorySubmit = async (data) => {
        setLoading(true);
        try {
            // Validate categories
            const categories = data.category.map(cat => cat.name.trim().replace(/\s/g, ''));
            
            // Check for empty categories
            if (categories.some(cat => cat === '')) {
                toast.error("Category cannot be empty",{position: "bottom-right"});
                setLoading(false);
                return;
            }

            // Check for invalid characters
            const invalidCategories = categories.filter(cat => !/^[a-zA-Z0-9]{2,50}$/.test(cat));
            if (invalidCategories.length > 0) {
                toast.error("Categories must be 2-50 characters long and contain only alphanumeric characters",{position: "bottom-right"});
                setLoading(false);
                return;
            }

            // Check for duplicates (case and space-insensitive)
            const uniqueCategories = [...new Set(categories.map(cat => cat.toLowerCase()))];
            if (uniqueCategories.length !== categories.length) {
                toast.error("Duplicate categories are not allowed",{position: "bottom-right"});
                setLoading(false);
                return;
            }

            // If all validations pass, update categories
            await updateCategory(categories);
            // toast.success(`Categories Updated Successfully`);
        } catch (error) {
            console.error("Failed to update categories:", error);
            toast.error("Failed to update categories",{position: "bottom-right"});
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Card className="max-w-[600px] sm:w-full">
                <CardHeader>
                    <CardTitle>Category</CardTitle>
                </CardHeader>

                {configs === undefined ? (
                    <CardContent>
                        <Loader2 className="mr-2 h-8 w-8 animate-spin" />
                    </CardContent>
                ) : (
                    <CardContent>
                        <form onSubmit={form.handleSubmit(onCategorySubmit)} id="category_config_form">
                            <div className="space-y-4">
                                {categoryFieldArray.fields.map((field, index) => (
                                    <div key={field.id} className="flex items-center gap-2">
                                        <Input
                                            type="text"
                                            {...form.register(`category.${index}.name`)}
                                            placeholder="Enter category name"
                                            className="w-full"
                                            disabled={field.name ? true : false}
                                        />
                                        {categoryFieldArray.fields.length > 1 && (
                                            <Button
                                                type="button"
                                                className="self-end"
                                                size="sm"
                                                variant="destructive"
                                                onClick={() => categoryFieldArray.remove(index)}
                                            >
                                                <Trash2 className="h-4 w-4" />
                                            </Button>
                                        )}
                                    </div>
                                ))}

                                <div className="flex justify-end mt-2">
                                    <Button
                                        type="button"
                                        className="font-medium border-r-0 add-item-order-btn"
                                        variant="default"
                                        size="sm"
                                        onClick={() => categoryFieldArray.append({ name: "" })}
                                    >
                                        Add More Category
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </CardContent>
                )}

                <CardFooter>
                    <Button
                        type="submit"
                        form="category_config_form"
                        className="w-full save-currency-btn"
                        variant="outline"
                    >
                        {loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                        Save Category Settings
                    </Button>
                </CardFooter>
            </Card>
            <ToastContainer />
        </>
    );
}

export default CategorySetting;